import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { getCreateOrder, getSpaceList, fileUpload, getQuestionCategoryList, createOrder, getCleanRuleList, getNewCleanRuleList } from '@api/maintenance';
import { getStorage } from '@utils/storage';
import { mapGetters } from 'vuex';
export default {
  name: 'DirectionsList',
  mixins: [common],
  data() {
    return {
      loading: false,
      headerTitle: '发起保洁',
      userInfo: JSON.parse(getStorage('user_info', 'localStorage')),
      // 空间一级分类
      fieldNames: {
        text: 'spaceName',
        value: 'spaceId',
        children: 'children'
      },
      // 层级
      showSpace: false,
      showQuestion: false,
      showDatePicker: false,
      spaceList: [],
      questionList: [],
      imgList: [],
      cleanTypeList: [],
      expectRepairDate: new Date(),
      form: {
        spaceName: null,
        spaceId: null,
        questionDes: null,
        questionCategoryId: null,
        questionCategoryName: null,
        expectRepairDate: null,
        expectRepairTimeSpan: null,
        contactsName: null,
        contactsMobile: null,
        contactsRemark: null,
        questionDesImgList: []
      }
    };
  },
  props: {},
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  created() {
    console.log(this.projectInfo);
    this.getSpaceList();
    this.getQusitonList();
  },
  mounted() {},
  methods: {
    // 空间层级
    getSpaceList() {
      getSpaceList({
        projectId: this.projectInfo.projectId
      }).then(res => {
        let data = res.data.data;
        this.spaceList = this.listToTree(data);
        console.log(this.spaceList);
      });
    },
    listToTree(oldArr) {
      oldArr.forEach(element => {
        const parentId = element.parentId;
        if (parentId !== 0) {
          oldArr.forEach(ele => {
            if (ele.spaceId === parentId) {
              if (!ele.children) {
                ele.children = [];
              }
              ele.children.push(element);
            }
          });
        }
      });
      // console.log('1711', oldArr);
      // 清除重复的
      oldArr = oldArr.filter(ele => ele.parentId === '0');
      oldArr = this.arrSort(oldArr);
      return oldArr;
    },
    arrSort(arr) {
      const newArr = arr.sort((a, b) => {
        return a.orderNum - b.orderNum;
      });
      newArr.map(item => {
        if (item.children && item.children.length > 0) {
          item.children = this.arrSort(item.children);
        }
      });
      return newArr;
    },
    spaceChange(e) {
      this.form.spaceName = e.selectedOptions[e.selectedOptions.length - 1].spaceFullName;
      let houseId = e.selectedOptions[e.selectedOptions.length - 1].id;
      getNewCleanRuleList({
        houseId
      }).then(res => {
        const data = res.data;
        this.cleanTypeList = data.result.map(i => {
          return {
            ...i,
            value: i.id,
            text: i.name
          };
        });
      });
    },
    questionChange(e) {
      this.form.questionCategoryId = e.value;
      this.form.questionCategoryName = e.text;
      this.showQuestion = false;
    },
    // 问题类型
    getQusitonList() {
      getQuestionCategoryList({
        type: 1
      }).then(res => {
        const data = res.data;
        if (data.code == 200) {
          this.questionList = this.listToQuestion(data.data);
        }
      });
    },
    listToQuestion(oldArr) {
      oldArr.forEach(element => {
        const parentId = element.leaderId;
        if (parentId !== 0) {
          oldArr.forEach(ele => {
            if (ele.id === parentId) {
              if (!ele.children) {
                ele.children = [];
              }
              ele.children.push(element);
            }
          });
        }
      });
      // console.log('1711', oldArr);
      // 清除重复的
      oldArr = oldArr.filter(ele => ele.leaderId === '0');
      oldArr = this.arrSort(oldArr);
      return oldArr;
    },
    changeDate(e) {
      this.form.expectRepairDate = this.timeFormat(this.expectRepairDate);
      this.showDatePicker = false;
    },
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let date = time.getDate();
      let hour = time.getHours();
      if (month < 10) month = '0' + month;
      if (date < 10) date = '0' + date;
      if (hour < 10) hour = '0' + hour;
      return year + '-' + month + '-' + date + ' ' + hour + ':00:00';
    },
    async onSubmit() {
      try {
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner'
        });
        this.loading = true;
        let data = {
          ...this.form,
          type: 2,
          projectId: this.projectInfo.projectId
        };
        const res = await createOrder(data);
        if (res.data.code === 200) {
          this.$toast('创建成功');
          this.$router.push({
            name: 'CleanDetail',
            query: {
              id: res.data.data.id
            }
          });
          // this.goBack()
        } else {
          // 请求失败
          await this.$toast.clear();
          await this.handleCallback({
            isError: true,
            errorMessage: res.data.message || '创建失败!'
          });
        }
      } catch (err) {
        // 请求失败
        await this.$toast.clear();
      } finally {
        this.loading = false;
      }
    },
    afterIDRead(file) {
      console.log(file);
      file.status = 'uploading';
      file.message = '上传中...';
      fileUpload(file).then(res => {
        const cb = res.data;
        if (cb.code === 200) {
          console.log(this.form.questionDesImgList, 'this.form.questionDesImgList');
          this.form.questionDesImgList.push({
            fileType: 1,
            url: cb.data.url,
            fileUrl: cb.data.url
          });
          file.status = 'none';
          file.message = '上传成功';
        }
        console.log(this.form.questionDesImgList, 'this.form.questionDesImgList');
      });
    },
    onOversize() {
      this.$toast('文件大小不能超过5M');
    },
    deleteImg(item) {
      console.log(item);
      this.imgList = this.imgList.filter(v => v.content != item.content);
      console.log(this.form.questionDesImgList, '珊瑚');
    },
    onShowQuestion() {
      this.showQuestion = true;
    }
  },
  filters: {}
  //
};